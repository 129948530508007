/** page-title **/

.page-title {
    position: relative;
    width: 100%;
    padding: 80px 0px 80px 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.page-title:before {
    position: absolute;
    content: '';
    // background-color: #1d2534;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
    opacity: 0.5;
}

.page-title h1 {
    position: relative;
    display: block;
    font-size: 55px;
    line-height: 60px;
    // color: #ffffff;
    font-weight: 700;
    margin-bottom: 12px;
}

.page-title .bread-crumb li {
    position: relative;
    display: inline-block;
    font-size: 20px;
    line-height: 30px;
    // color: #ffffff;
    padding-right: 15px;
    margin-right: 5px;
}

.page-title .bread-crumb li:last-child {
    padding: 0px !important;
    margin: 0px !important;
}

.page-title .bread-crumb li:before {
    position: absolute;
    content: '\f105';
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    top: 0px;
    right: 0px;
    font-size: 18px;
}

.page-title .bread-crumb li a {
    // color: #ffffff;
    text-decoration: none;
}

.page-title .bread-crumb li a:hover {
}

.page-title .bread-crumb li:last-child:before {
    display: none;
}
