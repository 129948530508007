.providercontainer{
    padding-right: 10rem;
    padding-left: 10rem;
    // background-color: black;
}
@media only screen and (max-width: 768px) {
    .providercontainer {
        padding-right: 1rem;
        padding-left: 1rem;
    }
}
.cardborder{
        border-radius: 5px;
            background: #ffff;
            // background: var(--vz-secondary-bg);
            border: 1px solid #e9ebec;
            box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, .075);
            padding: 10px;
            position: relative;
}
.validation-error {
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #f24e4e;
}
.btn-custom-color {
    background-color: #1e293b !important;
    color: #ffff !important;
    width: 100px;
    border-color: #1e293b !important;
}